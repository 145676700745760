<template>
  <div class="news">
    <div class="pageBanner">
      <div class="banner">
        <div class="describe">
          <div class="tit">新闻中心<span>/NEWS</span></div>
          <div class="txt">
            致力于帮助客户提供有竞争力的、安全可信赖的服务与解决方案。
          </div>
        </div>
      </div>
    </div>
    <div class="newsCont">
      <div class="tabs">
        <ul>
          <li
            v-for="(item, k) in tabs.slice(0, 1)"
            :key="k"
            @click="tabsCli(item.itemId)"
            :class="activeClass == item.itemId ? 'activeli' : ''"
          >
            {{ item.itemName }}
          </li>
        </ul>
        <div
          class="newsList"
          v-for="(item, k) in newList"
          :key="k"
          @click="newListDtail(item)"
        >
          <div class="newsTu">
            <img :src="item.imgUrl" alt="" />
          </div>
          <div class="newsText">
            <div class="rTit">{{ item.title }}</div>
            <div class="rText">{{ item.summary }}</div>
            <div class="rTime">{{ item.pubtime }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "news",
  data() {
    return {
      activeClass: 1,
      newList: [],
      tabs: [],
    };
  },
  mounted() {
    this.$http.getNewItemList().then((res) => {
      console.log(res)
      if (res.success) {
        this.tabs = res.newItemList;
      }
    });
    this.$http.getNewsList().then((res) => {
      if (res.success) {
        this.newList = res.newList;
      }
    });
  },
  methods: {
    tabsCli(id) {
      this.activeClass = id;
      this.$http.getNewsList({ itemType: id }).then((res) => {
        if (res.success) {
          this.newList = res.newList;
        }
      });
    },
    newListDtail(id) {
    let routeUrl= this.$router.resolve({
        path: "/news/detail",
        query: { newsId: id.newsId },
      });
       window.open(routeUrl.href, '_blank');
    },
    callback(key) {
      // console.log(key);
    },
  },
};
</script>
<style lang="scss">
@import "../scss/news.scss";
</style>